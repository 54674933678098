
// @import "bootstrap/scss/functions";
// @import "bootstrap/scss/variables";
// @import "bootstrap/scss/maps";
// @import "bootstrap/scss/mixins";
// @import "bootstrap/scss/utilities";

// $enable-gradients: true;
$enable-negative-margins: true;
$font-family-sans-serif:  'Nunito Sans', sans-serif;
$headings-font-family:    'Poppins', sans-serif;

$brandeis-blue:   #0066ff;
$palatinate-blue: #3333cc;
$chrysler-blue:   #6600cc;
$shade-blue:      #001524;

// $blue: #0066ff;
$blue: $brandeis-blue;
$purple: $chrysler-blue;

$danger:  #f27474;
$warning: #f8bb86;
$info:    #c9dae1;
$success: #a5dc86;

$dark: $shade-blue;

$body-bg: #f3f6ff;
$body-color: $shade-blue;

@import '../../sb-admin-pro/src/scss/core/variables/colors';

$primary:    $palatinate-blue;
$secondary:  $chrysler-blue;
$tertiary:   $indigo;
$quaternary: $pink;

// $link-color:    $dark;
$component-active-color: $white;
$component-active-bg:    $primary;
// $component-active-bg:         mix($gray-100, $primary, 1%);


$card-cap-bg: mix($white, $black, 97%);


$link-color: $component-active-bg;


// $grid-gutter-width: 30px;

$input-padding-y: 0.8rem;
$input-padding-x: 0.6rem;
$input-line-height: 1.5;

$btn-font-size: 1em;
$btn-border-width: 4px;
// $btn-border-radius: 30px;
// $btn-border-radius: 0.35rem;
$btn-padding-y: 8px;
$btn-padding-x: 50px;

$card-border-radius: 1em;

$menu-background-color: $component-active-bg;
$menu-link-color: $light;
$menu-link-hover-color: rgba($menu-link-color, .7);

$hamburger-padding: 25px;
$hamburger-close-color: $menu-background-color;
$hamburger-open-color: $menu-link-color;

$spacer: 1rem;
$spacers: (0: 0,
    1: $spacer * .25,
    2: $spacer * .5,
    3: $spacer,
    4: $spacer * 1.5,
    5: $spacer * 2.5,
    10: $spacer * 6,
    15: $spacer * 9,
    20: $spacer * 12,
    25: $spacer * 15,
    30: $spacer * 30);

//

$header-height: 350px;

// .btn {
//     letter-spacing: 2px;
//     text-transform: uppercase;
// }

/* _border-width-customs.scss */
$border-width-custom-1: 1px;
$border-width-custom-2: 2px;
$border-width-custom-3: 3px;
$border-width-custom-4: 4px;
$border-width-custom-5: 5px;
$border-width-custom-6: 6px;
$border-width-custom-7: 7px;
$border-width-custom-8: 8px;
$border-width-custom-9: 9px;
$border-width-custom-10: 10px;

$border-width-customs: ("1": $border-width-custom-1, "2": $border-width-custom-2, "3": $border-width-custom-3, "4": $border-width-custom-4, "5": $border-width-custom-5, "6": $border-width-custom-6, "7": $border-width-custom-7, "8": $border-width-custom-8, "9": $border-width-custom-9, "10": $border-width-custom-10);

$custom-colors: (

);

$theme-colors: (
	"brandeis-blue":   $brandeis-blue,
	"palatinate-blue": $palatinate-blue,
	"chrysler-blue":   $chrysler-blue,
	"shade-blue":      $shade-blue,
	"primary":         $primary,
	"secondary":       $secondary,
	"success":         $success,
	"info":            $info,
	"warning":         $warning,
	"danger":          $danger,
	"light":           $light,
	"dark":            $dark
);
