body {
	// &.cart-items,
	// &.cart-empty,
	// &.error {
	// 	background-image: linear-gradient(180deg, rgba(255,255,255, 0.01), rgba(255,255,255, 1) 85%)
	// 		, radial-gradient(ellipse at top left, rgba($primary, .3), transparent 50%)
	// 		, radial-gradient(ellipse at top right, rgba($secondary, .3), transparent 50%)
	// 		, radial-gradient(ellipse at center right, rgba($tertiary, .3), transparent 50%)
	// 		, radial-gradient(ellipse at center left, rgba($quaternary, .3), transparent 50%);
	// }

    // padding-top: $header-height;
    // &.error-page,
    // &.empty-cart-page {
    //     // height: 100%;
    //     .wrapper {
    //         // height: 100%;
    //         // display: flex;
    //         // align-items: center;
    //         // justify-content: center;
    //         // font-size: 1em;
    //     }
    // }

    a {
        text-decoration: none;
    }

	&.cart-items {
		.quantity {
			border: 1px solid var(--bs-dark);
			min-height: calc((1px * 2) + 2.5rem);
			button {
				border: 0;
				background-color: transparent;
			}
		}
		.disabled {
			cursor: not-allowed;
			pointer-events: all !important;
		}
	}

	.basel-cookies-popup {
		position: fixed;
		bottom: 0;
		left: 0;
		z-index: 1100;
		width: 100%;
		padding: 15px 30px;
		background-color: rgba(0,0,0,.9);
		border-top: 2px solid rgba(255,255,255,.1);
		color: #fff;
		opacity: 0;
		visibility: hidden;
		transform: translate3d(0,100%,0);
		transition: all .4s cubic-bezier(.784,.134,.14,.85);
		&.basel-cookies-popup-display {
			opacity: 1;
			visibility: visible;
			transform: translate3d(0,0,0);
		}

		.basel-cookies-inner {
			display: flex;
			align-items: center;
			justify-content: space-between;
			.cookies-info-text {
				padding-right: 30px;
				line-height: 1.4;
			}
			.cookies-buttons {
				flex-shrink: 0;
				.cookies-accept-btn {
					display: inline-block;
					font-size: 12px;
					line-height: 16px;
					padding-top: 7px;
					padding-bottom: 7px;
					padding-left: 14px;
					padding-right: 14px;
					text-transform: uppercase;
					letter-spacing: .3px;
					border-radius: 0;
					border: 2px solid #fff;
					color: #fff;
					text-decoration: none;
					text-align: center;
					transition: all .25s ease-in-out;
				}
				> a {
					margin-right: 20px;
					&:last-child {
						margin-right: 0;
					}
				}
			}
		}
	}



	&.p-item {
		ul.selector-item {
			list-style: none;
			margin: 0;
			padding: 0;
			li {
				padding: .8rem 1rem;
				border: 1px $dark solid;
			}

		}
	}

	footer {
		color: rgba(var(--bs-white-rgb), .8);
		h4 {
			color: rgba(var(--bs-white-rgb), .6);
		}
		a {
			color: inherit;
		}
		i {
			margin-right: 10px;
		}
	}
}
