// .cart-steps {
// 	// margin-top: 40px;
// 	border-bottom: solid 1px var(--bs-gray-300);
// 	// padding: 0 0 10px 0;
// 	> .bs-wizard-step {
// 		--bs-gutter-x: 0;
// 		// padding: 0;
// 		position: relative;
// 		+ .bs-wizard-step {}

// 		.bs-wizard-stepnum {
// 			color: #595959;
// 			font-size: 16px;
// 			margin-bottom: 5px;
// 		}
// 		.bs-wizard-info {
// 			color: #999;
// 			font-size: 14px;
// 		}
// 		> .bs-wizard-dot {
// 			// position: absolute;
// 			// top: 45px;
// 			// left: 50%;
// 			width: 48px;
// 			height: 48px;
// 			display: block;
// 			background: var(--bs-primary-soft);
// 			margin-top: -15px;
// 			margin-left: -15px;
// 			border-radius: 50%;
// 			display: flex;
// 			align-items: center;
// 			justify-content: center;
// 			// &:after {
// 			// 	content: ' ';
// 			// 	width: 24px;
// 			// 	height: 24px;
// 			// 	background: var(--bs-primary);
// 			// 	border-radius: 50px;
// 			// 	position: absolute;
// 			// 	top: 8px;
// 			// 	left: 8px;
// 			// }
// 		}
// 		> .progress {
// 			--bs-progress-height: 8px;
// 			--bs-progress-border-radius: 0;
// 			--bs-progress-box-shadow: none;
// 			--bs-progress-bg: var(--bs-gray-300);
// 			position: relative;
// 			// border-radius: 0px;
// 			// height: 8px;
// 			// box-shadow: none;
// 			margin: 20px 0;
// 			> .progress-bar {
// 				width: 0px;
// 				box-shadow: none;
// 				background: var(--bs-primary-soft);
// 			}
// 		}

// 		&.complete > .progress > .progress-bar {width:100%;}
// 		&.active > .progress > .progress-bar {width:50%;}
// 		&:first-child.active > .progress > .progress-bar {width:0%;}
// 		&:last-child.active > .progress > .progress-bar {width: 100%;}
// 		&.disabled > .bs-wizard-dot {
// 			background-color: var(--bs-gray-300);
// 			&:after {
// 				opacity: 0;
// 			}
// 		}

// 		&:first-child > .progress { left: 50%; width: 50%; }
// 		&:last-child > .progress { width: 50%; }
// 		&.disabled a.bs-wizard-dot{ pointer-events: none; }
// 	}
// }


.cart-steps {
	.nav-item {
		--nav-dot-bg-start: var(--bs-gray-100);
		--nav-dot-bg-end: var(--bs-gray-100);

		--nav-dot-color: var(--bs-gray-400);

		position: relative;
		&.active,
		&.complete {
			--nav-dot-color: var(--bs-white) !important;
		}
		&.active {
			--nav-dot-bg-start: var(--bs-primary) !important;
		}
		&.complete {
			--nav-dot-bg-start: var(--bs-primary) !important;
			--nav-dot-bg-end: var(--bs-primary) !important;
		}

		&:before,
		&:after {
			content: ' ';
			z-index: 0;
			position: absolute;
			top: 34px;
			width: 50%;
			height: 8px;
		}
		&:before {
			background-color: var(--nav-dot-bg-start);
		}
		&:after {
			background-color: var(--nav-dot-bg-end);
		}
		&:before {
			left: 0;
		}
		&:first-child {
			&:before {
				display: none;
			}
		}
		&:last-child {
			&:after {
				display: none;
			}
		}
		.nav-link {
			.nav-dot {
				position: relative;
				display: flex;
				align-items: center !important;
				justify-content: center !important;
				margin-right: auto !important;
    			margin-left: auto !important;
				border-radius: 50%;
				background-color: var(--nav-dot-bg-start);
				z-index: 1;
				width: 56px;
				height: 56px;
				color: var(--nav-dot-color);
			}
		}
	}
}










