// Override Bootstrap color system

$red: #e81500!default;
$orange: #f76400!default;
$yellow: #f4a100!default;
$green: #00ac69!default;
$teal: #00ba94!default;
$cyan: #00cfd5!default;
$blue: #0061f2!default;
$indigo: #5800e8!default;
$purple: #6900c7!default;
$pink: #e30059!default;

// Custom grayscale base hue variable
//
// Changing this to adjusts the hue of the grayscale

$grayscale-base-hue: $blue;

// Override Bootstrap grayscale with custom adaptive grayscale
//
// Mixes the $grayscale-base-hue variable with a desaturated
// black and white grayscale to produce a grayscale with slight color saturation

$white: #fff!default;
$gray-100: mix(#fcfcfc, $grayscale-base-hue, 96%)!default;
$gray-200: mix(#ececec, $grayscale-base-hue, 95%)!default;
$gray-300: mix(#e2e2e2, $grayscale-base-hue, 94%)!default;
$gray-400: mix(#d4d4d4, $grayscale-base-hue, 93%)!default;
$gray-500: mix(#b4b4b4, $grayscale-base-hue, 93%)!default;
$gray-600: mix(#717171, $grayscale-base-hue, 93%)!default;
$gray-700: mix(#505050, $grayscale-base-hue, 93%)!default;
$gray-800: mix(#3a3a3a, $grayscale-base-hue, 93%)!default;
$gray-900: mix(#242424, $grayscale-base-hue, 93%)!default;
$black: #000!default;

// Override Bootstrap default state colors

$primary: $blue!default;
$secondary: $purple!default;
$success: $green!default;
$info: $cyan!default;
$warning: $yellow!default;
$danger: $red!default;
$dark: $gray-900!default;
$light: $gray-100!default;

// Brand color variables
//
// Used to create custom button styles for popular brands
// You can add more brand color variables below, a good place to
// find official brand colors is https://brandcolors.net/

$facebook: #3b5998!default;
$twitter: #1da1f2!default;
$github: #333333!default;
$google: #ea4335!default;
