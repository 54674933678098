form {
	input.form-control {
		&[type=number] {
			padding-right: 0;
			text-align: right;

			&::placeholder {
				text-align: right;
			}
		}

		&::placeholder {
			color: #b0b0b0;
			opacity: 1;
		}
	}

	.error,
	.has-error {
		@extend .text-danger;

		.form-error {
			@extend .is-invalid;
		}

		.error-message {
			@extend .invalid-feedback;
		}
	}
}

.form-error {
	@extend .is-invalid;
}

.error-message {
	@extend .invalid-feedback;
}

.form-floating {
	> label,
	> .dataTable-input:focus~label,
	> .dataTable-input:not(:placeholder-shown)~label,
	> .dataTable-selector~label,.form-floating
	> .form-control-plaintext~label,
	> .form-control:focus~label,
	> .form-control:not(:placeholder-shown)~label,
	> .form-select~label {
		opacity: .5;
	}

	.select2-container--bootstrap-5 {
		z-index: auto;

		.select2-selection {
			// height: $form-floating-height;
			min-height: $form-floating-height;
			padding: $form-floating-padding-y $form-floating-padding-x;

			>.select2-selection__rendered {
				margin-top: .6rem;
				// margin-left: .25rem;
			}
		}
	}
}

.select2-selection__choice__remove {
	text-indent: 200% !important;
}
